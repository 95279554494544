import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Rodenkirchen: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Rodenkirchen,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Rodenkirchen"
          d="M643.8,914.6L632,912l-20-20h-42l-14-14v-12l24-10c9.7-14.6,30.4-43.8,41.4-57.3l3.7,2.9 c7.2,5.6,15.2,10,23.8,13.1l0,0c9,3.3,18.5,5.4,28.1,6.1c17.7,1.3,37.5,0.2,53,1.3l-12,32l4,4l-24,34l-14,2l6,14l-36,22l-4-14 L643.8,914.6z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 609.7645 865.0422)"
        >
          Rodenkirchen
        </text>
      </g>
    </>
  );
});
